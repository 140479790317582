<template>
  <div>
    <portal to="page-top-title">Edit Subject #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'subjects-single', params: { id: id } }" icon="fa-eye" text>Back to Subject</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="subjects-list" :url="`/subjects/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" :max="64" :disabled="!editable.name" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="categories" :disabled="!categories.length" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-input name="value" :model="formData.value" :min="3" :max="250" :disabled="!editable.value" required/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-input name="value_with_codes" title="Value with Shortcodes" :model="formData.value_with_codes" :min="3" :max="250" :disabled="!editable.value_with_codes">
              <template slot="info">
                <p>
                  <span>Shortcodes:</span>
                  <loader v-if="!shortcodes.length"/>
                  <code v-for="(shortcode, i) in shortcodes" :key="`shortcode-${i}`">{{ shortcode.code }}</code>
                </p>
              </template>
            </form-group-input>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import Btn from '@/views/components/simple/Btn'
import Loader from '@/views/components/simple/Loader'
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import Form from '@/modules/utils/Form'

export default {
  name: 'SubjectsEdit',
  metaInfo () {
    return { title: `Subject #${this.id} | Edit` }
  },
  components: {
    Btn,
    Loader,
    BoxForm,
    FormGroupCheck,
    FormGroupInput,
    FormGroupSelect
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      categories: [],
      shortcodes: [],
      editable: {},
      formData: {
        name: Form.types.input(),
        value: Form.types.input(),
        value_with_codes: Form.types.input(),
        active: Form.types.boolean(),
        category_id: Form.types.select()
      }
    }
  },
  created () {
    this.loadEntity()
    this.loadCategories()
    this.loadShortcodes()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/subjects/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.value.value = data.value
          this.formData.value_with_codes.value = data.value_with_codes
          this.formData.active.value = data.active
          this.formData.category_id.value = { id: data.category.id, name: data.category.name }
          this.editable = data.metadata.editable

          this.loaded = true
        })
    },
    loadCategories () {
      this.$http
        .get('/subjects/categories', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.categories = data.map((category) => { return { id: category.id, name: category.name } })
        })
    },
    loadShortcodes () {
      this.$http
        .get('/macros/shortcodes', { params: { type: 'subject' } })
        .then((res) => {
          const { data: { data } } = res

          this.shortcodes = data.map((code) => { return { code: code.code, name: code.title, descr: code.descr } })
        })
    }
  }
}
</script>
